<template>
  <v-hover v-slot="{hover}" >
    <v-sheet
        style="position : relative"
        class="wsRoundedLight mb-2"
        :style="`border : 1px solid ${wsBACKGROUND}`">

      <v-sheet
          @click="expand=!expand"
          class="d-flex  justify-space-between py-3 transition-ease-in-out pointer"
          :color="hover || edit || editDropDownOn ? wsLIGHTCARD : ''"
      >
        <v-sheet    color="transparent" class="d-flex"  >

          <!-- Expand Icon-->
          <v-icon
              :class="expand ? 'rotate' : ''"
              class="pointer ml-3"
              :color="wsACCENT">
            mdi-menu-right
          </v-icon>
          <!-- Component Type Icon-->
          <v-icon
              :color="wsATTENTION"
              class="mr-2"
          >
             mdi-order-bool-ascending-variant
          </v-icon>
          <!-- Component name Icon-->
          <h4
              :contenteditable="edit ? 'plaintext-only' : 'false'"
              :placeholder="$t('EnterComponentName')"
              ref="text"
              style="outline: none; font-size: 16px; font-weight: 700 !important "
              :style="getTextStyle()"
              class="font-weight-medium text">
            {{ index + 1 }}. {{ $t('DefaultQuestion') }}
            <span v-if="totalCorrectAnswers > 1" class="font-weight-regular" style="font-size: 13px">
              ({{$t('testing.multiple_choice.title')}}: {{ $t(`testing.multiple_choice.${question.multiple_type ? 'all' : 'single'}`) }})
            </span>
          </h4>

        </v-sheet>
        <div v-if="hover || edit || editDropDownOn " class="d-flex mr-4">
          <v-sheet width="35" color="transparent" class="pr-2">
            <ft-select
                @input="editAction"
                :items="actionsSelect"
                @expand="editDropDownOn = $event"
                left left-icons >
              <ws-icon-button  class="mr-4" v-if="hover || edit || editDropDownOn" />
            </ft-select>
          </v-sheet>

        </div>
      </v-sheet>

      <v-expand-transition>
        <div v-if="expand">

          <v-divider />

          <div  v-if="totalCorrectAnswers > 1">
            <div class="d-flex align-center justify-space-between" >

              <div class="py-3 px-8 ">
                <h4 class="mr-2">{{ $t('testing.multiple_choice.title')}} </h4>
                <h5 class="mr-2 font-weight-regular">{{ $t('testing.multiple_choice.description')}}</h5>
              </div>

              <ft-select
                  @input="editQuestion"
                  v-model="question.multiple_type"
                  :items="multipleQuestionTypeSelect"
              >
                <template #default="{text}">
                  <h5 class="font-weight-regular text-wrap">{{ text || $t('testing.multiple_choice.single') }}
                    <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                  </h5>
                </template>
              </ft-select>

            </div>
            <v-divider />
          </div>

          <div class="d-flex mt-4 px-8 justify-space-between">
            <h4 class="">{{ $t('Content') }}: </h4>
          </div>

          <ws-text-editor
              v-model="question.content"
              @input="editQuestion"
              :html="question.content_text"
              :placeholder="$t('EnterQuestionContent')"
              :question-id="question.uuid"
              no-file
          />

          <v-divider style="border-color: var(--wsBACKGROUND);margin-top: 11px; margin-bottom: -16px"/>

          <div class="pt-4"   :key="question.uuid">

            <!-- Default Question Type-->
            <div v-if="['default'].includes(question.type)">
              <answerEditor
                  v-for="(answer , i ) in question.answers"
                  @delete="deleteAnswer(i)"
                  :answer="answer"
                  :index="i"
                  :question-id="question.uuid"
                  :question="question"
                  :key="question.uuid + answer.uuid + i"
                  :total-answers="totalAnswers"
                  :total-correct-answers="totalCorrectAnswers"
              />
            </div>
            <!-- Select Question Type-->

            <div v-else-if="question.type === 'match'" class="mx-8 mt-8"     :style="`border : 1px solid ${wsBACKGROUND} !important`">
              <v-hover #default="{hover}" v-for="row in question.answers.length/2" :key="row + 'row'">
                <v-sheet
                    style="position: relative" class="d-flex "
                >
                  <v-sheet
                      v-for="column in 2" :key="`column_${row}_${column}`"
                      color="transparent"
                      max-width="50%"
                      width="50%"
                      :class="[{'pr-16' : (((row-1)*2) + column - 1)%2 !== 0 }]"
                      :style="`border : 1px solid ${wsBACKGROUND} !important`"
                  >
                    <answerEditor
                        class="fill-height"
                        :answer="question.answers[((row-1)*2) + column - 1]"
                        :index="((row-1)*2) + column - 1"
                        :question-id="question.uuid"
                        :question="question"
                        :total-answers="totalAnswers"
                        :total-correct-answers="totalCorrectAnswers"
                        :key="question.answers[((row-1)*2) + column - 1].uuid"
                    />

                  </v-sheet>

                  <v-sheet style="position:absolute; right : 0; top:4px; bottom: auto" min-width="40" color="transparent">
                    <v-btn
                        @click="deleteMatchAnswer(question.answers[((row-1)*2)])"
                        v-if="hover" :color="wsACCENT" icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-sheet>
                </v-sheet>

              </v-hover>
            </div>


            <ws-button
                @click="addAnswer(question.type)"
                label="AddAnswer"
                :color="wsDARKLIGHT"
                outlined
                style="border-radius: 0"
                block class="mt-6" left-icon="mdi-plus"
            />

          </div>

        </div>
      </v-expand-transition>

      <ws-dialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteQuestion')"
          close-delete
          display-confirm-delete
          @delete="deleteQuestion"
      />

    </v-sheet>
  </v-hover>
</template>

<script>
import answerEditor from "@/components/pages/westudy/admin/course/questionBanks/answerEditor";
import {mapActions, mapState} from "vuex";
export default {
  name: "questionEditor",
  components : {
    answerEditor
  },
  props : {
    question : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      edit : false,
      editDropDownOn : false,
      expand : false,
      displayDeleteDialog : false
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
    multipleQuestionTypeSelect() {
      return [
        { text : this.$t('testing.multiple_choice.single') , value : null },
        { text : this.$t('testing.multiple_choice.all')  , value : 'all' },
        // { text : this.$t('testing.multiple_choice.percent')  , value : 'percent' },
      ]
    },
    totalAnswers() {
      return this.question.answers.length
    },
    totalCorrectAnswers() {
      if ( !this.question.answers ) {
        return []
      }
      return this.question.answers.filter(el=>el.correct).length
    },
    actionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
    ...mapActions('tests', [
        'ADD_EDIT_BANK_ANSWER',
        'ADD_EDIT_BANK_QUESTION',
        'DELETE_QUESTION_ANSWER'
    ]),

    editAction(type) {
      if ( type === 'delete' ) {
        this.displayDeleteDialog = true
      }
      if ( type === 'duplicate' ) {
        this.$emit('duplicate')
      }

    },
    async editQuestion() {

      let data = JSON.parse(JSON.stringify(this.question))
      data.lang = this.selectedLang

      let result = await this.ADD_EDIT_BANK_QUESTION(data)
      if ( !result ) {
        return
      }
    },
    deleteQuestion() {
      this.$emit('delete')
      this.displayDeleteDialog = false
    },

    async addAnswer(type = 'default') {

      let data = {
        question_bank_question_id : this.question.uuid,
        lang : this.selectedLang
      }

      let result = await this.ADD_EDIT_BANK_ANSWER(data)
      if (!result) {
        return
      }
      result.content = []

      if ( type === 'default') {
        this.question.answers.push(result)
      } else  if ( type === 'match') {
        this.question.answers.push(...result)
      }


    },
    async deleteMatchAnswer(answer) {

      let result = await this.DELETE_QUESTION_ANSWER(answer.uuid)
      if ( !result )  {
        return
      }

      let index = this.question.answers.findIndex(el=>el.uuid === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)
      index = this.question.answers.findIndex(el=>el.match_parent === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)

    },
    deleteAnswer(index) {
      this.question.answers.splice(index,1)
    },

    getTextStyle() {
      let style = this.edit ? 'cursor : text;' : ' cursor : pointer;';
      return style
    },
    expandAction() {
      this.notify('Expand')
    }

  },
  mounted() {
    if (this.question.expand) {
      this.expand = true
    }
  }
}
</script>

<style scoped>
.rotate {
  transform: rotate(90deg);
}
</style>