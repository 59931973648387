<template>
  <div class="pb-16 fill-height" >

    <div v-if="!loading">
      <div class="pt-6 pb-16" />
      <questionEditor v-for="(question,i) in entity.questions"
                      @delete="deleteQuestion(question.uuid, i)"
                      @duplicate="duplicateQuestion(question.uuid)"
                      :question="question"
                      :index="i"
                      :key="question.uuid + i"/>



      <h5 class="font-weight-regular mt-5 text-center wsACCENT">
        {{ $t('AddQuestion')}}
      </h5>
      <div class="d-flex justify-center pb-16">
        <div class="d-flex justify-center mt-2 wsRoundedSemi overflow-hidden"
             style="border : 1px solid var(--wsACCENT); "
        >
          <div v-for="(item,i) in QUESTION_TYPES_SELECT" :key="'type' + i" class="d-flex" >

            <v-hover #default="{hover}">
              <v-sheet
                  @click="addQuestion(item.value)"
                  class="pa-3 pointer"
                  :color="hover ? (wsACCENT+33) : 'transparent'"
                  v-ripple
              >
                <h5 class="wsACCENT">{{ item.text }}</h5>
              </v-sheet>
            </v-hover>

            <v-divider
                v-if="i !== QUESTION_TYPES_SELECT.length - 1"
                style="border-color : var(--wsACCENT)"
                vertical
            />
          </div>
        </div>
      </div>



    </div>
    <div v-else class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT"></v-progress-circular>
    </div>


  </div>
</template>

<script>
import {mapActions, mapMutations,  mapState} from "vuex";
import questionEditor from "@/components/pages/westudy/admin/course/questionBanks/questionEditor";
import tests from "@/mixins/tests";
export default {
  name: "questionBankEditor",
  mixins : [tests],
  components : {
    questionEditor
  },
  props : {
    uuid : {
      type : String,
      default : '',
    },
    bankId : {
      type : String,
      default : '',
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
  },
  data() {
    return {
      loading : true,
      entity : {
        questions : []
      },
      defaultQuestionType : 'default',
      defaultQuestionPoints : 1,
    }
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('tests', [
        'EDIT_QUESTION_BANK',
        'GET_QUESTION_BANK_EDIT',
        'ADD_EDIT_BANK_QUESTION',
        'DELETE_QUESTION',
        'COPY_QUESTION'
    ]),
    ...mapMutations('courses',['INIT_PAGE']),

    async addQuestion(type = 'default') {
      let data = {
        question_bank_id : this.bankId,
        type : type,
        points : this.defaultQuestionPoints
      }

      let result = await this.ADD_EDIT_BANK_QUESTION(data)
      if ( !result ) {
        return
      }
      this.entity.questions.push(result)
      result.expand = true
    },
    async deleteQuestion(uuid, index ) {
      let result = await this.DELETE_QUESTION(uuid)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      this.entity.questions.splice(index,1)
      this.notify(this.$t('TestQuestionDeleted'))
    },
    async duplicateQuestion( uuid  ) {
      let result = await this.COPY_QUESTION(uuid)
      if (!result ) {
        return
      }
      this.entity.questions.push(result)
      this.entity = Object.assign({} , this.entity)
    },
    async editBankName(name) {
      let data = JSON.parse(JSON.stringify(this.entity))
      data.lang = this.selectedLang
      data.name = name
      let result = await this.EDIT_QUESTION_BANK(data)
      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))
    },

    async initPage() {
      this.loading = true
      let result = await this.GET_QUESTION_BANK_EDIT({uuid: this.bankId, lang: this.selectedLang})
      if (!result) {
        this.loading = false
        return
      }
      this.entity = result
      let name =  this.entity.name !== ' ' ? this.entity.name : this.$t('BlankQuestionBank')
      this.INIT_PAGE({
        icon : 'mdi-chevron-left',
        title : name,
        icon_route : this.businessDashLink('courses/' + this.uuid + '/question_banks'),
        width : 760,
        name_edit_action : this.editBankName
      })
      this.loading = false

    }

  },
  mounted() {
    if ( !this.uuid ) {
      return
    }
    this.initPage()
  }
}
</script>

<style scoped>

</style>