import { render, staticRenderFns } from "./questionBankEditor.vue?vue&type=template&id=49c794a2&scoped=true&"
import script from "./questionBankEditor.vue?vue&type=script&lang=js&"
export * from "./questionBankEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c794a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VDivider,VHover,VProgressCircular,VSheet})


/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
