<template>
  <div v-if="question.type !== 'match'">
    <v-hover :key="hover" v-slot="{hover}">
      <v-sheet
          :style="`border-bottom: 1px solid ${wsBACKGROUND};`"
          class="  pb-6"
      >

        <v-sheet
            :color="hover ? wsLIGHTCARD : 'transparent'" class="d-flex px-8 py-3  justify-space-between align-center">

          <v-switch
              :key="refresh"
              v-model="answer.correct"
              @change="editCorrectAnswer"
              class="mt-0 pt-0"
              hide-details dense inset color="green lighten-1" >
            <template #label>
              <h4  :style="`color : ${answer.correct ? 'mediumseagreen' : wsACCENT} !important`" class="">
                {{ answer.correct ? $t('CorrectAnswer') : $t('Answer') }}:
              </h4>
            </template>
          </v-switch>
          <div class="d-flex " @click="deleteAnswer">
            <!--        <ws-icon-button small icon="mdi-content-copy"></ws-icon-button>-->
            <ws-icon-button
                v-if="hover"
                @click="deleteAnswer"
                :tooltip="$t('DeleteAnswer')"
                icon="mdi-delete"></ws-icon-button>
          </div>
        </v-sheet>

        <ws-text-editor
            v-model="answer.content"
            @input="editAnswer"
            :question-id="questionId"
            :placeholder="$t('EnterAnswerContent')"
            :html="answer.content_text"
            no-file
        />
      </v-sheet>
    </v-hover>
  </div>

  <div  v-else  class="fill-height">

    <v-sheet class="d-flex flex-row pa-1 fill-height">
      <div style="width: 100%" class="fill-height">

        <ws-text-field
            @change="editAnswer"
            v-model="answer.content_text"
            :placeholder="$t('EnterText')"
            solo
            flat
            area
            rows="1"
            width="100%"
        />
      </div>
    </v-sheet>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "answerEditor",
  props : {
    answer : {
      type : Object,
      default() { return {} }
    },
    question : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
      default : 0
    },
    questionId : {
      type : String,
      default : ''
    },
    totalAnswers : {
      type : Number,
    },
    totalCorrectAnswers : {
      type : Number,
    }
  },
  data() {
    return {
      refresh : 0
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
  },
  methods : {
    ...mapActions('tests', [
        'ADD_EDIT_BANK_ANSWER',
        'DELETE_QUESTION_ANSWER'
    ]),
    editCorrectAnswer(value) {
      if ( !value ) {
        if ( this.totalCorrectAnswers === 1 ) {
          this.answer.correct = true
          this.answer = JSON.parse(JSON.stringify(this.answer))
          this.refresh++
          return this.notify(this.$t('QuestionAnswerDeleteMinimumCorrectError'))
        }
      }
      this.editAnswer()
    },
    editAnswer() {
      let data = JSON.parse(JSON.stringify(this.answer))
      data.lang = this.selectedLang
      let result = this.ADD_EDIT_BANK_ANSWER(data)
      if (!result) {
        return
      }
      result.content = []
    },
    async deleteAnswer() {
      if ( this.totalAnswers === 1) {
        return this.notify(this.$t('QuestionAnswerDeleteMinimumError'))
      }
      if ( this.answer.correct && this.totalCorrectAnswers === 1 ) {
        return this.notify(this.$t('QuestionAnswerDeleteMinimumCorrectError'))
      }

      let result = await this.DELETE_QUESTION_ANSWER(this.answer.uuid)
      if ( !result )  {
        return
      }
      this.$emit('delete')
      this.notify(this.$t('AnswerDeleted'))
    },

  }
}
</script>

<style scoped>

</style>